@import './assets/style/reset.css';
@import './assets/style/defult.css';

.wrapper-go-terrain .caption a.see-all:after,
.wrapper-main-visuel .update a.icon:after,
.wrapper-main-visuel:before,
.wrapper-split .caption h3 span:after,
.wrapper-split .caption h3:before,
body:after {
  content: '';
}

table>thead>tr>th {
  padding: 1rem !important;
}

table>tbody>tr>td {
  /* text-align: center; */
}

table>tbody>tr>td>img {
  width: 60px;
}

.w-50px {
  width: 50px;
}

h3,
h4 {
  font-family: SuiGenerisBk-Regular;
  font-style: normal;
  color: #4c4c4c;
  margin-bottom: 1.5625rem;
  font-weight: 400;
}

.scroll-to-top {
  z-index: 9999;
}

::marker {
  color: red;
  font-weight: 600;
}

.ck .ck-reset {
  width: 240px !important;
}

.ck .ck-dropdown:not(.ck-heading-dropdown) {
  display: none;
}

@media screen and (max-width: 992px) {
  /* .scroll-to-top {
    display: none;
  } */

  .wrapper-main-visuel .update {
    display: none;
  }
}