.download-main {
    margin-top: 7rem;
}

.wrapper-bg {
    position: relative;
    background: #262626;
    padding: 50% 0 2.8125rem;
    margin: 2.8125rem 0 1.875rem;
    text-align: center;
}

.wrapper-bg h3 {
    color: #fff;
    margin: 0;
}

.app-logo {
    position: absolute;
}

.app-logo__cover {
    width: 500px;
}

.app-logo__cover--direct {
    width: 80px;
}


@media screen and (max-width: 992px) {
    .app-logo {
        bottom: 35% !important;
    }
}

@media screen and (max-width: 768px) {
    .app-logo__cover--direct {
        margin: 0 auto;
    }
}

@media (min-width: 48em) {
    .wrapper-bg {
        text-align: left;
        padding: 3.75rem 0;
    }

    .wrapper-bg .col-12 {
        position: inherit;
    }

    .wrapper-bg h3 {
        max-width: calc(100% - 60%);
        display: inline-block;
    }

    .app-logo {
        left: auto;
        right: 5%;
        bottom: 20%;
        max-width: 600px;
        width: auto;
        max-height: 49vw;

    }

    .wrapper .blocs {
        position: relative;
        padding: 3.125rem 0;
        margin-bottom: 2.8125rem;
        z-index: 1;
    }

    .wrapper .blocs.left {
        text-align: right;
    }

    .wrapper .blocs.center {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }

    .wrapper .blocs.center.left {
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }

    .wrapper .blocs {
        position: relative;
        padding: 3.125rem 0;
        margin-bottom: 2.8125rem;
        z-index: 1;
    }

    .wrapper .blocs.left .bloc-txt,
    .wrapper .blocs.right {
        text-align: left;
    }

    .wrapper .blocs.center {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
    }
}

@media (min-width: 62em) {
    .wrapper-bg {
        padding: 7.5rem 0;
    }
}

@media (min-width: 75em) {
    .wrapper-bg {
        padding: 11rem 0;
        margin: 2.8125rem 0 0;
    }

    .wrapper-bg h3 {
        max-width: calc(100% - 50%);
    }

    .wrapper .blocs {
        padding: 10rem 0;
        margin-bottom: 3.125rem;
    }

    .wrapper .blocs.center {
        padding: 3.125rem 0;
    }

    .wrapper .blocs {
        padding: 10rem 0;
        margin-bottom: 3.125rem;
    }

    .wrapper .blocs.center {
        padding: 3.125rem 0;
    }
}