*,
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
legend,
main,
nav,
section {
    display: block;
}

dl,
ol,
p,
ul {
    margin-bottom: 1rem;
}

dl,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul {
    margin-top: 0;
}