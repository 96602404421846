.wrapper-split {
    background: #131313;
}

.wrapper-split .bloc-orx {
    text-align: right;
}

.wrapper-split .bloc-deus {
    background: #fff;
}

.wrapper-split .bloc-deus .img-block,
.wrapper-split .bloc-orx .img-block {
    margin: 0 auto !important;
}

.wrapper-split .bloc-text {
    padding: 5rem;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
}

.wrapper-split .bloc-deus .bloc-text {
    -webkit-align-items: flex-start;
    align-items: flex-start;
}

.wrapper-split .bloc-deus .bloc-text {
    background: #FFF;
}

.wrapper-description h2,
.wrapper-split h3 {
    font-family: OpenSansRegular;
    font-style: normal;
    color: #fff;
}

.wrapper-split h3 {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    max-width: 100%;
}

.wrapper-split .bloc-deus .caption h3 {
    margin: 1rem auto;
    color: #1a171b;
}

.wrapper-split .bloc-deus .bloc-text.text-black h3,
.wrapper-split .bloc-orx .bloc-text.text-black h3 {
    color: #1a171b;
}

.wrapper-go-terrain h3,
.wrapper-split h3 span {
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
}

.wrapper-split h3 span {
    font-family: SuiGenerisRg-Italic;
    display: block;
}

.wrapper-split .bloc-deus h3 span {
    color: #4c4c4c;
}

.wrapper-split .bloc-deus .bloc-text.text-black h3 span,
.wrapper-split .bloc-orx .bloc-text.text-black h3 span {
    color: #4c4c4c;
}

.wrapper-split h3 span:after {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    margin: 1.5rem 0;
}

.wrapper-split .bloc-deus h3 span:after {
    background: #e31720;
}

.wrapper-split .bloc-deus a:hover,
.wrapper-split .bloc-deus h3 span:after {
    background: #e31720;
}

.wrapper-split a {
    font-family: SuiGenerisRg-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    padding: 8px 15px;
    letter-spacing: 1px;
    margin-top: 1.5rem;
    z-index: 90;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
}

.wrapper-split .bloc-deus a {
    background: #fff;
    border: 2px solid #e31720;
    color: #e31720;
}

.wrapper-split .bloc-deus a:hover {
    color: #fff;
    background: #e31720
}

.wrapper-split .bloc-orx {
    text-align: right;
    z-index: 999;
    position: relative;
}

.text-white {
    color: #fff !important;
}

.wrapper-split .bloc-orx .bloc-text {
    background: #161616;
    -webkit-align-items: flex-end;
    align-items: flex-end
}

.wrapper-go-terrain h3,
.wrapper-split h3 span {
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
}

.wrapper-split h3 span {
    font-family: SuiGenerisRg-Italic;
    display: block;
}

.wrapper-split .bloc-orx h3 span:after {
    background: #d3c982;
    margin: 1.5rem 0 1.5rem;
}

.wrapper-split .bloc-orx a {
    border: 2px solid #d3c982;
    color: #d3c982;
}

.wrapper-split .bloc-orx a:hover {
    background: #d3c982;
    color: #262626
}

.wrapper-split .bloc {
    padding: 1rem 2rem !important;
}

.wrapper-split .bloc-orx .caption a {
    bottom: 13px;
    right: 180px;
}

@media (max-width: 564px) {
    .wrapper-split {
        position: unset !important;
        height: unset !important;
    }

    .wrapper-split h3 span {
        font-size: calc(1.171875vw + 18.38px);
    }

    .wrapper-split .div-img {
        position: unset !important;
        transform: unset !important;
    }

    .wrapper-split .bloc-deus .caption,
    .wrapper-split .bloc-orx .caption {
        padding: 1rem 0 !important;
    }
}

@media (min-width: 48em) {
    .wrapper-split .bloc {
        padding: 5rem calc((100% - 700px) / 2);
    }

    .wrapper-split .caption h3 span {
        font-size: calc(1.171875vw + 18.38px);
    }
}

@media (max-width: 768px) {
    .wrapper-split .caption h3:before {
        width: 100px;
        height: 100px;
        margin-bottom: 1rem;
    }

    .wrapper-split .text-black h3::before {
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .wrapper-split h3 span {
        font-size: 30px;
    }

    .wrapper-split .bloc-deus .caption a {
        top: -270px !important;
    }
}

@media (max-width: 992px) {
    .wrapper-split {
        flex-direction: column !important;
    }

    .wrapper-split .bloc {
        overflow: hidden;
    }

    .wrapper-split .bloc-deus {
        background: #fff;
    }

    .wrapper-split .div-img {
        width: 170px;
        display: block;
        margin: 0 auto;
        visibility: hidden;
        vertical-align: middle;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 9;
    }

    .wrapper-split .bloc-deus .div-img.go-direction {
        width: 210px;
        -webkit-transition: left 1s !important;
        transition: left 1s !important;
    }

    .wrapper-split .caption {
        width: 100%;
        visibility: visible !important;
        opacity: 1;
        display: inline-block;
        vertical-align: middle;
    }

    .wrapper-split .caption h3 {
        font-family: OpenSansRegular;
        font-weight: 400;
        font-style: normal;
        color: #1a171b;
        font-size: 18px;
        margin: 0;
    }

    .wrapper-split .bloc-deus .caption.text-black h3,
    .wrapper-split .bloc-orx .caption.text-black h3 {
        color: #1a171b;
    }

    .wrapper-split .caption h3 span {
        font-family: SuiGenerisRg-Italic;
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
    }

    .wrapper-split .bloc-deus .caption.text-black h3 span,
    .wrapper-split .bloc-orx .caption.text-black h3 span {
        color: #4c4c4c;
    }

    .wrapper-split .caption h3 span:after {
        display: block;
        width: 60px;
        height: 5px;
        margin: 1.5rem 0;
    }

    .wrapper-split .bloc-deus .caption h3 span:after {
        background: #e31720;
    }

    .wrapper-split .caption a {
        font-family: SuiGenerisRg-Regular;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #000;
        text-transform: uppercase;
        display: inline-block;
        padding: 8px 15px;
        letter-spacing: 1px;
        margin-top: 1.5rem;
    }

    .wrapper-split .bloc-deus .caption a {
        border: 2px solid #e31720;
        color: #e31720;
    }

    .wrapper-split .bloc-deus .caption a:hover {
        background-color: #e31720;
        color: #fff;
    }

    .wrapper-split .bloc-orx {
        background: #161616;
    }

    .wrapper-split .caption {
        width: 100%;
        visibility: hidden;
        display: inline-block;
        vertical-align: middle;
    }

    .wrapper-split .bloc-orx .caption h3 span:after {
        background: #d3c982;
    }

    .wrapper-split .bloc-orx .caption a {
        border: 2px solid #d3c982;
        color: #d3c982;
    }

    .wrapper-split .bloc-orx .caption a:hover {
        background-color: #d3c982;
        color: #000;
    }

    .text-white>h3 {
        color: #fff !important;
    }

    .wrapper-split .bloc-orx .caption a {
        right: 130px;
    }
}

@media (min-width: 62em) and (max-width:75.0525em) {
    .wrapper-split .bloc-text {
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}