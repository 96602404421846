.acc-faq,
.main-thematique {
    background-color: #f2f2f2;
    margin: 1.25rem 0;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .1)
}

.acc-faq>h3,
.main-thematique a {
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal
}

.faq-title {
    text-align: left !important;
}

.main-thematique {
    transition: all .4s ease
}

.main-thematique:hover {
    transform: perspective(1px) scale(1.01);
    -webkit-transform: perspective(1px) scale(1.01)
}

.main-thematique a {
    text-align: left;
    display: block;
    color: #000;
    padding: 1.5rem;
    text-decoration: none;
}

.main-thematique a:hover {
    color: unset;
}

.main-thematique a:active,
.main-thematique a:focus {
    outline: none;
    box-shadow: none;
}

.acc-faq>h3 span,
.acc-faq>h3:after {
    display: inline-block;
    vertical-align: top
}

.acc-faq.disable {
    max-height: 65px;
    overflow: hidden
}

.acc-faq>h3 {
    padding: 20px;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    margin: 0
}

.acc-faq>h3[aria-expanded="true"]::after {
    transform: rotate(180deg) translate3d(0, 0, 0);
    -webkit-transform: rotate(180deg) translate3d(0, 0, 0);
    -moz-transform: rotate(180deg) translate3d(0, 0, 0);
    -ms-transform: rotate(180deg) translate3d(0, 0, 0);
    -o-transform: rotate(180deg) translate3d(0, 0, 0);
}

@media (min-width: 480px) {
    .acc-faq>h3 {
        font-size: calc(.390625vw + 16.13px)
    }
}

@media (min-width: 992px) {
    .acc-faq>h3 {
        font-size: 20px
    }
}

.acc-faq>h3 span {
    width: calc(100% - 31px);
    padding-right: 10px
}

.acc-faq>h3:after {
    content: '';
    background: url(../../../public/img/staticPic/bottom-arrow.svg) center center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    transition: all .5s ease;
    height: 25px;
    width: 25px;
    transform: rotate(1deg) translate3d(0, 0, 0);
    -webkit-transform: rotate(1deg) translate3d(0, 0, 0)
}

.acc-faq ol,
.acc-faq ul {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 15px
}

.acc-faq .content-faq .content-inner-faq a:not(.bouton, .lien-picto),
.acc-faq ol a,
.acc-faq ol li:before,
.acc-faq ul a {
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal;
    color: #e31720
}

@media (min-width: 480px) {

    .acc-faq ol,
    .acc-faq ul {
        font-size: calc(.390625vw + 13.13px)
    }
}

@media (min-width: 992px) {

    .acc-faq ol,
    .acc-faq ul {
        font-size: 17px
    }
}

.acc-faq ol a,
.acc-faq ul a {
    font-size: 15px
}

@media (min-width: 480px) {

    .acc-faq ol a,
    .acc-faq ul a {
        font-size: calc(.390625vw + 13.13px)
    }
}

@media (min-width: 992px) {

    .acc-faq ol a,
    .acc-faq ul a {
        font-size: 17px
    }
}

.acc-faq ol a:hover,
.acc-faq ul a:hover {
    color: #e31720
}

.acc-faq ol ol,
.acc-faq ol ol ol,
.acc-faq ol ol ul,
.acc-faq ol ul,
.acc-faq ol ul ol,
.acc-faq ol ul ul,
.acc-faq ul ol,
.acc-faq ul ol ol,
.acc-faq ul ol ul,
.acc-faq ul ul,
.acc-faq ul ul ol,
.acc-faq ul ul ul {
    padding-left: 23px
}

.acc-faq ol li:not(:last-child),
.acc-faq ul li:not(:last-child) {
    margin-bottom: 5px
}

.acc-faq ul {
    padding-left: 20px
}

.acc-faq ul li {
    /* list-style-image: url(../../../public/img/picto/) */
}

.acc-faq ul ul li {
    /* list-style-image: url(../assets/img/picto/puce-2.jpg) */
}

@media (min-width: 48em) {
    .acc-faq ul {
        padding-left: 35px
    }
}

.acc-faq ol {
    counter-reset: counter;
    list-style: none;
    padding-left: 34px
}

.acc-faq ol li {
    position: relative;
    counter-increment: counter
}

.acc-faq ol li::marker {
    content: none;
    display: none
}

.acc-faq ol li:before {
    content: counter(counter) ". ";
    position: absolute;
    left: -24px;
    line-height: 32px;
    width: 32px;
    height: 32px;
    top: -6px;
    font-size: 18px
}

@media (min-width: 480px) {
    .acc-faq ol li:before {
        font-size: calc(.5555555556vw + 15.33px)
    }
}

@media (min-width: 1200px) {
    .acc-faq ol li:before {
        font-size: 22px
    }
}

.acc-faq .content-faq {
    padding: 15px 20px 20px
}

.acc-faq .content-faq .content-inner-faq p {
    font-size: 15px
}

@media (min-width: 480px) {
    .acc-faq .content-faq .content-inner-faq p {
        font-size: calc(.390625vw + 13.13px)
    }
}

@media (min-width: 992px) {
    .acc-faq .content-faq .content-inner-faq p {
        font-size: 17px
    }
}

.acc-faq .content-faq .content-inner-faq a:not(.bouton, .lien-picto) {
    font-size: 15px
}

@media (min-width: 480px) {
    .acc-faq .content-faq .content-inner-faq a:not(.bouton, .lien-picto) {
        font-size: calc(.390625vw + 13.13px)
    }
}

@media (min-width: 992px) {
    .acc-faq .content-faq .content-inner-faq a:not(.bouton, .lien-picto) {
        font-size: 17px
    }
}

.acc-faq .content-faq .content-inner-faq a:not(.bouton, .lien-picto):hover {
    color: #e31720
}

.acc-faq.active>h3::after {
    transform: rotate(180deg) translate3d(0, 0, 0);
    -webkit-transform: rotate(180deg) translate3d(0, 0, 0)
}