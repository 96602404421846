header nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    justify-content: flex-end;
    z-index: 99;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    transition: .5s 1.5s;
}

header nav.active {
    left: 0;
    transition: .5s;
}

header nav.active>div {
    left: 0;
    opacity: 1;
    transition: .5s .5s;
}

header nav ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

header nav.active>div>ul>li {
    position: relative;
    left: 0;
    opacity: 1;
}

header nav.active>div>ul>li:nth-child(1) {
    transition-delay: .5s;
}

header nav ul.level1>li {
    display: inline-block;
    width: 210px;
}

header nav ul.level1>li.sub-level {
    -webkit-box-shadow: inset 0 0 0 0 rgba(227, 23, 33, 0);
    -moz-box-shadow: inset 0 0 0 0 rgba(227, 23, 33, 0);
    box-shadow: inset 0 0 0 0 rgba(227, 23, 33, 0);
    -webkit-transition: .3s;
    transition: .3s;
}

header nav ul.level1>li>a {
    display: block;
    font-family: SuiGenerisLt-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #bfbfbf;
    padding: 32px 30px;
    position: relative;
    -webkit-transition: .3s;
    transition: .3s;
}

header nav ul.level1>li.sub-level>a:after {
    content: '';
    position: absolute;
    left: 23px;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 0 solid #e31720;
    z-index: 100;
    -webkit-transition: .3s;
    transition: .3s;
}

header nav ul.level1>li:hover.sub-level {
    -webkit-box-shadow: inset 0 -4px 0 0 #e31721;
    -moz-box-shadow: inset 0 -4px 0 0 #e31721;
    box-shadow: inset 0 -4px 0 0 #e31721
}

header nav ul.level1>li:hover.sub-level>a:after {
    border-top: 7px solid #e31720
}

header nav ul.level1>li:hover>a {
    text-decoration: none;
    color: #fff
}

header nav .layer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    /* position: fixed; */
    width: 100%;
    /* height: 100%;
    top: 0;
    right: -100%; */
    z-index: 11;
    background: #262626;
    -webkit-transition: .5s ease-out;
    transition: .5s ease-out;
}

.layer .layer-3 {
    background: transparent;
}

header nav .layer.active {
    right: 0;
    transition: .5s;
}

header nav ul.level1 .layer-2 a {
    -webkit-transition: ease-out .3s;
    transition: ease-out .3s
}

header nav ul.level1 .layer-2 a:hover {
    opacity: .5;
    text-decoration: none
}

header nav ul.level1 .layer-2 ul li:first-child {
    display: none
}

header nav ul.level1 .layer-2 ul li.menu-go-terrain a,
header nav ul.level1 .layer-2 ul li.menu-link a {
    cursor: pointer !important;
    -webkit-transition: ease-out .3s;
    transition: ease-out .3s
}

header nav ul.level1 .layer-2 ul li.menu-go-terrain a:hover,
header nav ul.level1 .layer-2 ul li.menu-link a:hover {
    opacity: .5 !important;
    text-decoration: none
}

header nav ul.level1 .layer-2 ul li a.link-all {
    display: none
}

header nav ul.level1 .layer-2 ul li:first-child {
    display: none;
}

header nav ul.level1 .layer-2>.container>ul>li.li-accessoires {
    width: calc((100%/3) * 2)
}

header nav ul.level1 .layer-2>.container>ul>li.li-accessoires ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
}

header nav ul.level1 .layer-2>.container>ul>li>a {
    /* font-family: SuiGenerisLt-Regular; */
    font-weight: 600;
    font-style: normal;
    color: #f3ec9d;
    font-size: 22px;
    cursor: default;
    text-decoration: none;
}

header nav ul.level1 .layer-2>.container>ul>li>a:hover {
    opacity: 1
}

header nav ul.level1 .layer-2>.container>ul>li>a span.go-terrain {
    font-family: SuiGenerisLt-Italic;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 24px
}

header nav ul.level1 .layer-2>.container>ul>li.accessoires {
    background: #2b2b2b;
    text-align: center;
    margin: 0 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

header nav ul.level1 .layer-2>.container>ul>li.accessoires a {
    font-family: SuiGenerisBk-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #bebebe
}

header nav ul.level1 .layer-2>.container>ul>li.accessoires a:after {
    content: '';
    width: 87px;
    height: 24px;
    display: block;
    margin: 0 auto;
    /* background: url(../assets/img/content/deus-logo.png) center center no-repeat; */
    -webkit-background-size: contain;
    background-size: contain
}

header nav ul.level1 .layer-2>.container>ul>li.update {
    /* background: url(../assets/img/content/deus-update.jpg) top center no-repeat; */
    -webkit-background-size: contain;
    background-size: contain;
    position: relative
}

header nav ul.level1 .layer-2>.container>ul>li.update a {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #2b2b2b;
    padding: 10px;
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    color: #bfbfbf;
    font-size: 14px
}

.menu-link {
    display: inline-block;
    height: 100%;
}

header nav ul.level1 .layer-2>.container>ul>li .layer-3 ul li a {
    display: inline-block;
    padding: 0;
    margin-left: 2rem;

    max-width: 250px;
}

header nav ul.level1 .layer-2>.container>ul>li .layer-3 ul li a {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #fff
}

header nav ul.level1 .layer-2>.container>ul>li .layer-3 ul li a:hover {
    color: #fff
}

@media screen and (max-width:576px) {
    .menu-toggle {
        background: #0000005e !important;
    }
}

@media screen and (max-width: 992px) {
    header nav>div {
        z-index: 11;
        left: -100%;
        opacity: 0;
        transition: .5s;
        position: relative;
    }

    header nav .layer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: -100%;
        z-index: 11;
        background: #262626;
        -webkit-transition: .5s ease-out;
        transition: .5s ease-out;
    }

    header nav>div>ul>li {
        position: relative;
        left: -100%;
        opacity: 0;
        -webkit-transition: 1s ease-out;
        transition: 1s ease-out;
        transition-delay: 0s;
        -webkit-backface-visibility: hidden;
    }

    .level1 {
        display: flex;
        flex-direction: column;
    }

    .level1 .sub-level a {
        padding: .5rem 1rem;
    }

    header nav ul.level1>li.sub-level>a:after {
        display: none;
    }

    /* header nav ul.level1 .layer-2 {
        position: initial;
        top: -100%;
        left: 0;
        width: 100vw;
        display: block;
        background: transparent;
    } */

    /* header nav ul.level1 .layer-2>.container>ul {
        flex-direction: column;
    } */

    header nav ul.level1>li:hover.sub-level {
        box-shadow: none;
    }

    .layer .layer-3 {
        flex-direction: column;
    }

    .layer .layer-3>ul {
        height: 100vh;
        width: 100vw;
        background: #222;
        /* vertical-align: middle; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media (min-width: 992px) {
    header nav ul.level1>li:hover>.layer-2 {
        display: block
    }

    header nav ul.level1 .layer-2 {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: rgba(0, 0, 0, .85);
        display: none
    }

    header nav ul.level1 .layer-2>.container>ul {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        padding: 2.5rem 0
    }

    header nav ul.level1 .layer-2>.container>ul>li {
        width: calc(100%/3)
    }

    header nav ul.level1 .layer-2>.container>ul>li>a:after {
        content: '';
        width: 30px;
        height: 1px;
        background: #e31720;
        display: block;
        margin-top: .5rem;
        margin-bottom: .5rem
    }

    /* .layer.layer-3 {
        position: absolute;
        right: -100%;
    } */
}