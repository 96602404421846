.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0;
    padding: 8px 5px !important;
    background: 0 0;
    margin-top: 7rem;
    margin-bottom: 0;
    font-size: 14px;
}

.breadcrumb li {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
}

.breadcrumb li a {
    color: #e31720;
}

.breadcrumb>li+li:before {
    content: '/';
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    margin: 0 5px;
    color: grey;
}

.breadcrumb>li+li:before {
    content: '/';
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    margin: 0 5px;
    color: grey;
}

.breadcrumb li:last-child a,
.breadcrumb li:last-child span {
    color: grey;
    font-family: OpenSansBold;
    font-weight: 700;
    font-style: normal;
}