h2 {
    font-family: SuiGenerisRg-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    margin-bottom: 5px;
}

h1 {
    color: #e31720;
    margin-top: 0;
    font-size: 24px;
}

.form-group p.alerte,
label {
    font-size: 14px;
    margin-bottom: 0;
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
}

.btn-closefilter {
    position: absolute;
    z-index: 11;
    top: 0;
    right: 0;
    padding: 15px;
}

.bouton {
    font-family: SuiGenerisRg-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    padding: 10px 15px;
    margin: 10px 0;
    letter-spacing: 2px;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
    text-align: center;
    cursor: pointer;
    background: rgba(255, 255, 255, 0);
}

.btn-results {
    position: fixed;
    display: block;
    width: 100%;
    z-index: 11;
    margin: 0;
    bottom: 0;
    left: 0;
    background: #149A39;
    border: 2px solid #149A39;
    color: #fff;
    opacity: 0;
    -webkit-transition: opacity .5s;
    transition: opacity .5s;
}

.wrapper-controls {
    border-bottom: 1px solid gray;
    margin: 15px 0 25px;
    padding-bottom: 10px;
}

.wrapper-controls p.results {
    margin: 0;
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal;
}

.wrapper-view {
    text-align: right;
}

.wrapper-view .btn-filter,
.wrapper-view .btn-viewgrid,
.wrapper-view .btn-viewlist {
    padding: 0 10px 0 0;
    display: inline-block;
}

.wrapper-view .btn-filter,
.wrapper-view .btn-viewgrid,
.wrapper-view .btn-viewlist {
    padding: 0 10px 0 0;
    display: inline-block;
}

.wrapper-view .btn-viewgrid,
.wrapper-view .btn-viewlist {
    opacity: .6;
    -webkit-transition: .3s;
    transition: .3s;
}

.wrapper-view .btn-viewgrid.active,
.wrapper-view .btn-viewlist.active {
    opacity: 1;
}

.wrapper-view .btn-filter:before,
.wrapper-view .btn-viewgrid:before,
.wrapper-view .btn-viewlist:before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
}

.wrapper-view .btn-viewgrid:before {
    background: url(../../../public/img/staticPic/ic_apps_black_48px.svg) center center no-repeat;
    background-size: contain;
}

.wrapper-view .btn-filter,
.wrapper-view .btn-viewgrid,
.wrapper-view .btn-viewlist {
    padding: 0 10px 0 0;
    display: inline-block;
}

.wrapper-view .btn-viewgrid,
.wrapper-view .btn-viewlist {
    opacity: .6;
    -webkit-transition: .3s;
    transition: .3s;
}

.wrapper-view .btn-viewlist:before {
    background: url(../../../public/img/staticPic/ic_list_black_48px.svg) center center no-repeat;
    background-size: contain;
}

.wrapperClic {
    cursor: pointer;
}

.liste .encart-product-list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: start;
    justify-content: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    column-gap: 1rem;
}

.container-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100px !important;
}

/* 
.notouch .liste .container-img:before {
    content: '';
    width: 70px;
    height: 70px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../../../public/img/staticPic/picto-liste-hover.svg) center center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    z-index: 9;
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
} */

.liste .encart-product-grid .container-img img {
    width: 100%;
    height: 100px;
}

/* .notouch .liste .container-img:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: rgba(0, 0, 0, .6);
    z-index: 8;
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
} */

.notouch .liste .container-caption {
    -webkit-transition: .3s;
    transition: .3s;
}

/* .notouch .liste .encart-product-list .container-img:before {
    width: 38px;
    height: 35px
} */

.notouch .liste .item:hover .container-img:after,
.notouch .liste .item:hover .container-img:before {
    opacity: 1
}

.notouch .liste .item:hover .container-caption {
    opacity: .5
}

.liste .item .caption {
    text-align: center;
    padding: 5px 0;
}

.liste .item p.title,
.liste .item p.title a {
    font-family: SuiGenerisRg-Regular;
    font-weight: 400;
    font-style: normal;
    color: #e31720;
}

.liste .item p.title {
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
}

.liste .item div.desc h1,
.liste .item div.desc h2,
.liste .item div.desc h3,
.liste .item div.desc h4,
.liste .item div.desc h5,
.liste .item div.desc h6,
.liste .item div.desc p {
    /* display: none; */
    text-align: left;
    font-size: 15px;
    font-weight: 500 !important;
    color: #262626;
}

.liste .encart-product-list div.desc {
    display: block !important;
}

.show-more {
    display: block;
    background: #f2f2f2;
    width: 100%;
    padding: 15px 0;
    text-align: center;
}

.show-more .loader-cycle-disks {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    width: 2.2em;
    height: 1em;
}

@media (min-width: 480px) {
    h2 {
        font-size: calc(.2777777778vw + 16.67px);
    }

    h1 {
        font-size: calc(1.5277777778vw + 16.67px);
    }

    label {
        font-size: calc(-.1953125vw + 14.94px);
    }

    .checkbox[type=checkbox]:checked+label,
    .checkbox[type=checkbox]:not(:checked)+label {
        font-size: calc(.2777777778vw + 16.67px);
    }

    p {
        font-size: calc(.2777777778vw + 16.67px);
    }

    .liste .item p.title a {
        font-size: calc(.4166666667vw + 14px);
    }

    .liste .item div.desc {
        font-size: calc(.6944444444vw + 11.67px);
    }
}

@media (min-width: 62em) {
    h2 {
        text-align: center;
    }

    h1 {
        text-align: center;
        margin-bottom: 45px;
    }

    .filtres {
        background: #f2f2f2;
        padding: 15px;
    }

    label {
        font-size: 13px;
    }

    .btn-closefilter,
    .wrapper-view .btn-filter {
        display: none;
    }

    .bouton {
        padding: 10px 25px;
    }

    .btn-results {
        display: none;
    }

    .wrapper-controls {
        margin: 0 0 25px;
    }

    .btn-closefilter,
    .wrapper-view .btn-filter {
        display: none;
    }

}

@media (min-width: 1200px) {
    h2 {
        font-size: 20px;
    }

    h1 {
        font-size: 35px;
    }

    .checkbox[type=checkbox]:checked+label,
    .checkbox[type=checkbox]:not(:checked)+label {
        font-size: 20px;
    }

    p {
        font-size: 20px;
    }

    .liste .item p.title {
        font-size: 19px;
    }

    .liste .item p.title a {
        font-size: 19px;
    }

    .liste .item div.desc {
        font-size: 20px;
    }
}

/*  */
input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
hr,
input {
    overflow: visible;
}

.product-wrapper {
    padding-bottom: 0 !important;
}

/* Main Content */
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
legend,
main,
nav,
section {
    display: block;
}