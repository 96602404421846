.sb-nav-fixed .sb-topnav {
    z-index: 1039;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
    width: 225px;
    height: 100vh;
    z-index: 1038;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
    padding-top: 56px;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
    overflow-y: auto;
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: 225px;
    top: 56px;
}

.nav-link {
    justify-content: start !important;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
    margin-right: 0.5rem;
}

.sb-topnav.navbar-dark #sidebarToggle {
    color: rgba(255, 255, 255, 0.5);
}

.sb-topnav.navbar-light #sidebarToggle {
    color: #212529;
}

.sb-sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu {
    flex-grow: 1;
}

.sb-sidenav .sb-sidenav-menu .nav {
    flex-direction: column;
    flex-wrap: nowrap;
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
    padding: 1.75rem 1rem 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
    display: flex;
    align-items: center;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
    font-size: 0.9rem;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
    display: inline-block;
    margin-left: auto;
    transition: transform 0.15s ease;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
    transform: rotate(-90deg);
}

.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
    margin-left: 1.5rem;
    flex-direction: column;
}

.sb-sidenav .sb-sidenav-footer {
    padding: 0.75rem;
    flex-shrink: 0;
}

.sb-sidenav-dark {
    background-color: #212529;
    color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
    color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: rgba(255, 255, 255, 0.25);
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
    color: #fff;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
    color: #fff;
}

.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
    color: #fff;
}

.sb-sidenav-dark .sb-sidenav-footer {
    background-color: #343a40;
}

.sb-sidenav-light {
    background-color: #f8f9fa;
    color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
    color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link {
    color: #212529;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
    color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
    color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover {
    color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active {
    color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
    color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-footer {
    background-color: #e9ecef;
}

#layoutSidenav {
    display: flex;
}

#layoutSidenav #layoutSidenav_nav {
    flex-basis: 225px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    z-index: 1038;
    transform: translateX(0);
}

#layoutSidenav #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);

}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1037;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}


.sidebar-btn {
    position: absolute;
    top: 10px;
    left: .5rem;
}

.offcanvas {
    width: 200px !important;
    transition: all 400ms ease-in-out !important;
}

.offcanvas-header {
    height: 57.9px;
}

.offcanvas-body {
    background-color: #212529 !important;

}

.btn-close {
    color: #fff !important;
}

@media (min-width: 992px) {

    #layoutSidenav #layoutSidenav_content {
        margin-left: 0;
        transition: margin 0.15s ease-in-out;
    }

    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
        transform: translateX(-225px);
    }

    .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
        display: none;
    }
}