footer,
footer .footer-nl form {
    position: relative;
}

footer {
    margin-top: 2rem;
    padding: 3rem 3rem 0 3rem;
    background-color: #262626;
}

footer label.footer,
footer p.footer {
    font-family: SuiGenerisLt-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #fff;
    margin-bottom: .5rem;
    line-height: 1.5;
}

footer label.footer:after,
footer p.footer:after {
    content: '';
    width: 30px;
    height: 1px;
    background: #e31720;
    display: block;
    margin-top: .5rem;
}

footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-rs {
    display: flex;
    align-items: center;
    gap: 1rem;
}

footer .footer-rs ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem .5rem;
}

.footer-rs li a {
    font-size: 1.2rem;
    color: #fff;
}

/* .footer-rs li a.facebook:hover {
    color: #1773EA;
}

.footer-rs li a.youtube:hover {
    color: #000;
}

.footer-rs li a.instagram:hover {
    color: #000;
}

.footer-rs li a.twitter:hover {
    color: #000;
}

.footer-rs li a.linkedin:hover {
    color: #000;
} */

footer ul.links,
footer ul.links-apps {
    margin-bottom: 30px;
}

footer ul.links li a,
footer ul.links li p {
    font-family: OpenSansLight;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: #fff;
    padding: 2px 0;
}

footer a,
footer a:hover {
    color: #333;
    transition: .5s;
}

footer a {
    display: block;
}

footer ul.links li a,
footer ul.links li p {
    font-family: OpenSansLight;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: #fff;
    padding: 2px 0;
}

footer ul.links,
footer ul.links-apps {
    margin-bottom: 30px;
}

footer ul.links-apps li a,
footer ul.links-apps li p {
    font-family: OpenSansLight;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: #fff;
    margin: 0;
}

footer .footer-nl,
footer .footer-rs {
    margin-bottom: 30px;
}

footer label.footer,
footer p.footer {
    font-family: SuiGenerisLt-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #fff;
    margin-bottom: .5rem;
    line-height: 1.5;
}

footer,
footer .footer-nl form {
    position: relative;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

footer .footer-nl form input {
    color: #fff !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

footer .footer-nl form input.form-control {
    font-family: OpenSansLight;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    background: 0 0;
    padding: .375rem 45px .375rem 0;
    border: 0;
    border-bottom: 1px solid #b2b2b2;
}

footer .footer-nl form input#nl-send {
    font-family: OpenSansBold;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background: 0 0;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

footer a {
    display: block;
    color: #333;
    transition: .5s;
}

footer a:hover {
    text-decoration: none;
    opacity: .5
}

footer .footer-rs ul li {
    margin-right: 10px;
}

footer .footer-rs ul li a {
    width: 35px;
    height: 35px;
    display: block;
}

footer .footer-links ul li a {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #b2b2b2;
}

footer .footer-links a.agoravita {
    width: 100px;
    height: 30px;
    display: block;
    margin: 0 auto;
    opacity: .6;
}

.copy-right {
    background-color: #000;
}

.copy-right p {
    margin-bottom: 0 !important;
    padding: .5rem 1rem;
    font-size: 13px;
}

@media (min-width: 480px) {

    footer label.footer,
    footer p.footer {
        font-size: calc(.390625vw + 16.13px);
    }

    footer label.footer,
    footer p.footer {
        font-size: calc(.390625vw + 16.13px);
    }

    footer .footer-rs {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: left;
    }

    footer .footer-links ul li a {
        font-size: calc(-.1953125vw + 14.94px);
    }
}

@media (min-width: 576px) {
    .d-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 48em) {

    footer .footer-rs p,
    footer .footer-rs ul {
        vertical-align: middle;
        display: inline-block;
    }

    footer .footer-rs p {
        font-family: SuiGenerisLt-Italic;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #fff;
        margin: 0 10px 0 0;
    }

    footer .footer-rs p,
    footer .footer-rs ul {
        vertical-align: middle;
        display: inline-block;
    }

    footer .footer-rs ul li {
        display: inline-block;
        vertical-align: top;
    }

    footer .footer-rs ul li a {
        width: 25px;
        height: 25px;
    }

    footer .footer-links {
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }

    footer .footer-links a.agoravita {
        margin: 0;
    }

}

@media (min-width: 992px) {

    footer label.footer,
    footer p.footer {
        font-size: 20px;
    }

    footer .footer-rs {
        float: none;
        width: 40%;
    }

    footer .footer-nl,
    footer .footer-rs {
        margin-bottom: 30px;
    }

    footer label.footer,
    footer p.footer {
        font-size: 20px;
    }

    footer .footer-rs {
        float: none;
        width: 40%;
    }

    footer .footer-rs p {
        display: block !important;
    }

    footer .footer-links {
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: flex-end;
        align-items: flex-end;
    }

    footer .footer-links ul {
        margin: 0;
    }

    footer .footer-links ul li a {
        font-size: 13px;
    }
}

@media (min-width: 75em) {
    footer {
        padding: 6rem 0 0 0;
        overflow: hidden;
        -webkit-background-size: auto;
        background-size: auto;
    }

    footer .footer-nl {
        width: 100%;
        display: block;
    }

    footer .footer-rs {
        width: 100%;
        text-align: left;
    }

    footer .footer-rs p {
        display: inline-block !important;
    }
}