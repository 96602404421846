.margin-top-20 {
    margin-top: 20px;
}

.swiper-container {
    position: relative;
    z-index: 1;
    margin: 0px auto;
    overflow: hidden;
    list-style: none;
    padding: 0px;
}

.swiper-slide,
.swiper-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.swiper-wrapper {
    z-index: 1;
    display: flex;
    transition-property: transform, -webkit-transform;
    box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0px, 0px);
}

.swiper-container-vertical>.swiper-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.wrapper-pushs p {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-size: 15px;
    height: 90px;
}

.wrapper-pushs h5,
.wrapper-pushs p {
    color: #4c4c4c;
    overflow: hidden;
    font-style: normal;
}

.wrapper-pushs a {
    font-family: SuiGenerisLt-Regular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #e31720 !important;
}

.wrapper-pushs a span {
    padding-left: .25rem;
    display: none;
    vertical-align: middle;
}

.wrapper-pushs a span {
    width: 0;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
}

.wrapper-pushs a:before {
    content: '';
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    background: url(../../../public/img/staticPic/picto-plus.svg) center center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out;
}

.wrapper-pushs a:hover span {
    width: 120px
}

.wrapper-pushs a:hover:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.wrapper-pushs a:hover {
    text-decoration: none
}

.swiper-slide {
    flex-shrink: 0;
    transition-property: transform, -webkit-transform;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    /* background-image: url(./../../../public/img/staticPic/01.svg); */
    left: 10px;
    right: auto;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 27px 44px;
    background-repeat: no-repeat;
    background-position: center center;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    /* background-image: url(./../../../public/img/staticPic/03.svg); */
    right: 10px;
    left: auto;
}

.swiper-container {
    position: relative;
    z-index: 1;
    margin: 0px auto;
    overflow: hidden;
    list-style: none;
    padding: 0px;
}

.swiper-slide {
    flex-shrink: 0;
    transition-property: transform, -webkit-transform;
}

.swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
}

.swiper-container-main img {
    width: 100%;
}

.swiper-scrollbar {
    position: relative;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal>.swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%;
}

.wrapper-pushs .swiper-container-push .swiper-scrollbar {
    margin-top: 1.5625rem;
    position: relative;
    margin-bottom: 15px;
}

.wrapper-video {
    background: rgb(242, 242, 242);
    padding: 2.8125rem 0px;
    margin: 2.8125rem 0px 0px;
    overflow: hidden;
}

.wrapper-video ul {
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    padding: 0px 0px 1rem;
    margin: 0px;
    list-style: none;
    overflow: scroll;
}

.wrapper-video ul li {
    flex-shrink: 0;
    margin-right: 30px;
    width: 80%;
}

.bouton.btn-rouge {
    color: rgb(227, 23, 32);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(227, 23, 32);
    border-image: initial;
}

.bouton.btn-rouge:hover {
    background: #e31720;
    color: #fff;
}

.wrapper {
    /* margin: 2.8125rem 0px; */
    overflow: hidden;
}

.wrapper-bg-light {
    background: rgb(242, 242, 242);
    padding: 2.8125rem 0px;
    margin: 2.8125rem 0px;
}

.wrapper-bg-light h3 {
    margin-top: 0px;
}



.wrapper-bg-light ul.doc {
    padding: 0px;
    margin: 0px;
}

.wrapper ul li {
    list-style-image: url(./../../../public/img/staticPic/02.jpg);
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #1a171b;
}

.wrapper ul li strong {
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal;
}

.wrapper ul li:not(:last-child) {
    margin-bottom: 8px;
}

.wrapper ul li ul li {
    list-style: circle;
    color: rgb(26, 23, 27);
}

.wrapper ul li a,
.wrapper div p a strong {
    color: #e31720;
}

.wrapper ul li a:hover,
.wrapper div p a strong:hover {
    text-decoration: underline;
}

.wrapper-bg-light ul.doc li {
    list-style: none;
    transition: all 0.3s ease 0s;
}

.wrapper-bg-light ul.doc li a span {
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal;
    font-size: 17px;
    color: rgb(227, 23, 32);
    display: block;
}

.wrapper-bg-light ul.doc li a.libelle-lien {
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: rgb(76, 76, 76);
    display: block;
    line-height: 1.2;
    vertical-align: middle;
    margin-bottom: 5px;
}

.wrapper-bg-light ul.doc li.dl .libelle-lien::before {
    content: "";
    width: 45px;
    height: 45px;
    display: block;
    margin: 0px auto 8px;
    background: url(/public/img/staticPic/03.svg) center center / contain no-repeat !important;
}

@media (min-width: 48em) {

    .wrapper .blocs.center {
        display: flex;
        align-items: center;
    }

    .wrapper .blocs.left .bloc-txt,
    .wrapper .blocs.right {
        text-align: left;
    }

    .wrapper .blocs {
        position: relative;
        margin-bottom: 2.8125rem;
        z-index: 1;
        padding: 3.125rem 0px;
    }

    .wrapper .blocs img {
        position: absolute;
        z-index: -1;
        width: 375px;
    }

    .wrapper .blocs .bloc-txt {
        display: inline-block;
        width: calc(100% - 350px);
        padding: 30px;
        background: rgb(242, 242, 242);
    }

    .d-md-block {
        display: block !important;
    }

    .wrapper-bg-light ul.doc.no-swiper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .wrapper-bg-light ul.doc li {
        text-align: center;
        width: calc(33.3333%);
    }


}

@media (min-width: 992px) {

    .align-items-lg-center {
        align-items: center !important;
    }

    .thumbs {
        position: relative;
        width: 54px;
        margin-right: 15px;
        height: 330px;
        overflow: hidden;
    }

    .img,
    .thumbs {
        float: left;
    }

    .thumbs .thumb {
        position: relative;
        cursor: pointer;
        margin: 0px;
    }

    .thumbs .thumb img {
        opacity: 0.7;
        transition: all 0.3s ease 0s;
    }

    .thumbs .thumb.active img {
        opacity: 1;
    }

    .thumbs .swiper-button-next,
    .thumbs .swiper-button-prev {
        position: absolute;
        width: 54px;
        height: 54px;
        display: block;
        left: 0px;
        z-index: 1;
        opacity: 1;
        background: url(../../../public/img/staticPic/02.svg) center center / 20px 38px no-repeat rgba(255, 255, 255, 0.8);
        margin: 0px;
        transition: all 0.3s ease 0s;
    }

    .thumbs .swiper-button-next {
        bottom: 0;
        top: auto;
    }

    .img,
    .thumbs {
        float: left;
    }

    .d-lg-none {
        display: none !important;
    }

    .wrapper-video ul {
        max-width: 960px;
        padding-right: 15px;
        padding-left: 15px;
        justify-content: center;
        margin: 0px auto;
        overflow: hidden;
    }

    .wrapper-video ul li {
        flex-shrink: 1;
        margin-right: 30px;
        width: calc(33.3333% - 20px);
        padding-top: 25%;
    }


    .wrapper-pushs .swiper-container-push .swiper-pagination .swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #fff;
        border: 1px solid #e31720;
        opacity: 1;
    }

    .wrapper-pushs .swiper-container-push .swiper-pagination .swiper-pagination-bullet-active {
        background: #e31720;
    }

    .wrapper-pushs .swiper-container-push .swiper-pagination {
        position: relative;
        bottom: 0;
    }
}

@media (min-width: 75em) {

    .thumbs {
        width: 80px;
        height: 365px;
    }

    .thumbs .swiper-button-next,
    .thumbs .swiper-button-prev {
        width: 80px;
        height: 80px;
    }

    .wrapper-pushs .swiper-container-push .swiper-pagination {
        margin-top: 30px;
    }

    .thumbs .swiper-button-next.swiper-button-disabled,
    .thumbs .swiper-button-prev.swiper-button-disabled {
        opacity: 0;
    }

    .img {
        width: calc(100% - 95px);
    }

    .specifications {
        margin-top: 7.5rem 0px;
    }

    .wrapper.wrapper-specs {
        margin: 0 0 7.5rem;
    }

    .wrapper .blocs.center {
        padding: 3.125rem 0px;
    }

    .wrapper .blocs {
        margin-bottom: 3.125rem;
        padding: 10rem 0px;
    }

    .wrapper .blocs img {
        width: 555px;
        display: inline-block;
    }

    .wrapper .blocs .bloc-txt {
        width: calc(100% - 530px);
    }

    .wrapper-video ul {
        max-width: 1140px;
    }
}


/* swiper */
.swiper {
    height: 465px !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    border-radius: 100% !important;
    background: #fff !important;
    border: 1px solid #e31720 !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background: #e31720 !important;
}

.mySwiper2 img {
    height: 440px !important;
}

.mySwiper2 .swiper-pagination {
    bottom: -.25rem !important;

}