.logo-wrapper {
    position: relative;
}

.logo-cover {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -2rem;
    left: 0;
}

.image-information input {}

.image-information textarea {
    height: 10rem;
}

.feature-title {
    position: relative;
}

.feature-subtitle {
    position: absolute;
    right: 0%;
    bottom: 0;
    font-size: 12px;
    font-weight: 550;

}

.feature-subtitle span {
    direction: rtl;
    color: rgb(254, 11, 32);
}

/* @media screen and (max-width: 992px) {
    .feature-subtitle {
        left: 30% !important;
    }
} */