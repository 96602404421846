header .header-top {
    position: relative;
    text-align: right;
    padding: 0 0 24px;
}

header .header-top a {
    font-size: 10px;
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    color: #bfbfbf
}

header .header-top:before {
    content: '';
    position: absolute;
    right: 61%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 29px solid #333;
    border-left: 40px solid transparent
}

header .header-top:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 61%;
    z-index: -1;
    background: #333
}

header .header-top ul.lang {
    padding: 0;
    margin: 0 0 0 10px;
    list-style-type: none;
    display: inline-block
}

header .header-top ul.lang li {
    display: inline-block
}

header .header-top ul.lang li:not(:last-child):after {
    content: '';
    width: 1px;
    height: 10px;
    display: inline-block;
    background: #bfbfbf;
    vertical-align: middle;
    margin: 0 0 0 5px
}

header .header-top ul.lang li a {
    text-transform: uppercase
}

header .header-top ul.lang li a.active {
    color: #fff
}

header .header-top ul.lang li a img {
    opacity: 50%;
}

header .header-top ul.lang li a.active img {
    opacity: 100%;
}

.legende_compte_mobile {
    display: none;
}

.legende_compte {
    display: inline-block;
}

/* from cdn */
.flag-icon {
    display: initial;
    background-size: 0;
}

#selectpicker {
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 1000;
    display: none;
    border: 1px solid #CCC;
    display: none;
    text-align: left;
    background-color: #000;
    width: 150px;
    padding: 0px 15px 10px 15px;
}

#selectpicker .flag-icon {
    background-position-x: 0 !important;
    background-position-y: 0px !important;
    background-size: 20% 80%;
}

#selectpicker .flag-icon::before {
    display: none;
}

.flag-icon-sw {
    /* background-image: url("Flag_of_Swahili.gif"); */
}

.flag-icon-ligue_arabe {
    /* background-image: url("arab.svg"); */
}

#selectpicker span {
    color: #FFF;
    padding-left: 30px;
    padding-bottom: 2px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border-bottom: 2px solid #000000;
}

#selectpicker span:hover {
    border-bottom: 2px solid #e31720;
}

#choix_langues {
    cursor: pointer;
}

.mentions_langues {
    color: #FFF;
    font-size: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    header .header-top ul.lang li a {
        width: 23px;
    }

    header .header-top ul.lang li a img {
        width: 19px;
    }
}

@media (min-width: 36em) {
    header .header-top {
        background: #333;
        padding-left: 20%;
    }

    header .header-top:before {
        right: auto;
        left: 15%;
        border-right: 40px solid transparent;
        border-bottom: 33px solid #262626;
        border-left: 0;
        border-top: 0
    }

    header .header-top:after {
        left: 0;
        right: auto;
        width: 15%;
        z-index: 0;
        background: #262626
    }

    header .header-top ul.links-top {
        padding: 0;
        margin: 0;
        list-style: none;
        float: left
    }

    header .header-top ul.links-top li {
        display: inline-block;
        vertical-align: top
    }

    header .header-top ul.links-top li:not(:last-child) {
        margin-right: 10px
    }

    header .header-top a {
        display: inline-block
    }
}

@media (max-width: 667px) {
    .legende_compte_mobile {
        display: inline-block;
    }

    .legende_compte {
        display: none;
    }
}

@media (min-width: 62em) {
    header .header-top:before {
        border-bottom: 35px solid #262626;
    }
}

@media (min-width: 75em) {
    header .header-top {
        padding-left: 12%;
    }

    header .header-top:before {
        left: 10%;
    }

    header .header-top:after {
        width: 10%;
    }
}