@font-face {
    font-family: vazir;
    font-style: normal;
    font-weight: 900;
    src: url('../../../public/fonts/Vazir.woff') format('woff');
}


.admin-panel {
    font-family: vazir;
}

.form-label {
    font-size: 18px !important;
    font-weight: 600;
}

textarea {
    width: 100%;
    height: 150px;
    padding: 5px;
    border-color: #c3c3c3;
    text-align: left;
    resize: none !important;
}

textarea:focus {
    outline: none;
}