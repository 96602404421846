.custum-file-upload {
    height: 80px;
    width: 15%;
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e8e8e8;
    background-color: #212121;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 48px 35px -48px #e8e8e8;
}

.custum-file-upload .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custum-file-upload .icon svg {
    width: 50px;
    fill: #e8e8e8;
}

.custum-file-upload .text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custum-file-upload .text span {
    font-weight: 400;
    color: #e8e8e8;
}

.custum-file-upload input {
    display: none;
}

.imags-uploader {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
}

.image-preview {
    position: relative;
    width: 60px;
}

.imags-uploader i {
    position: absolute;
    left: -6px;
    color: red;
    font-size: 18px;
    cursor: pointer;
    top: -12px;
    font-weight: bold;
}

.imags-uploader label:first-child {
    font-size: calc(-.1953125vw + 14.94px);

}

/* Add Uploader */
.button {
    position: relative;
    width: 120px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #34974d;
    background-color: #3aa856;
}

.button,
.button__icon,
.button__text {
    transition: all 0.3s;
}

.button .button__text {
    transform: translateX(6px);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    -webkit-transform: translateX(6px);
    -moz-transform: translateX(6px);
    -ms-transform: translateX(6px);
    -o-transform: translateX(6px);
}

.button .button__icon {
    position: absolute;
    transform: translateX(80px);
    height: 100%;
    width: 39px;
    background-color: #34974d;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translateX(80px);
    -moz-transform: translateX(80px);
    -ms-transform: translateX(80px);
    -o-transform: translateX(80px);
}

.button .svg {
    width: 30px;
    stroke: #fff;
}

.button:hover {
    background: #34974d;
}

.button:hover .button__text {
    color: transparent;
}

.button:hover .button__icon {
    width: 118px;
    transform: translateX(0);
}

.button:active .button__icon {
    background-color: #2e8644;
}

.button:active {
    border: 1px solid #2e8644;
}