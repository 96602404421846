@font-face {
    font-family: OpenSans;
    src: url(../../../public/fonts/vazir/Vazir-Light.woff) format("woff2");
}

* {
    font-family: OpenSans !important;
}

body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

body,
caption {
    text-align: left;
}

a {
    color: #e31720;
    background-color: transparent;
    text-decoration: none !important;
    font-weight: 400;
}

a:hover {
    color: rgb(0, 86, 179);
    text-decoration: underline !important;
}

img {
    vertical-align: middle;
    border-style: none;
    width: 100%;
}

*:not(.simulator) {
    scrollbar-width: none;
}

div {
    display: block;
    unicode-bidi: isolate;
}

.d-none {
    display: none !important;
}

.text-truncate,
svg:not(:root) {
    overflow: hidden;
}

.ck {
    width: 100% !important;
}


.pdf-icon {
    color: red;
    font-size: 5rem !important;
}

@media (min-width: 480px) {
    body {
        font-size: calc(.78125vw + 12.25px);
    }
}

@media (min-width: 576px) {
    .d-sm-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 667px) {
    .mon_compte_picto {
        display: none;
    }
}