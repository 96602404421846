.filtres .filter,
.filtres .filter ul {
    width: 100%;
    display: block;
}

.filtres .filter ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.filtres .filter ul li {
    font-family: OpenSansBold;
    font-weight: 700;
    font-style: normal;
    color: #5f5f5f;
    font-size: 15px;
}

.filtres .filter ul li:not(:last-child) {
    margin-bottom: 10px;
}

.filtres .filter ul.links {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.filtres .filter ul.links li a {
    font-family: SuiGenerisBk-Regular;
    font-weight: 400;
    font-style: normal;
    color: #262626;
    font-size: 17px;
    display: block;
    line-height: 1.3;
}

.filtres .filter ul.links li.select a {
    font-family: SuiGenerisRg-Regular;
    font-weight: 600;
    font-style: normal;
    color: #000;
}

.checkbox[type=checkbox]:checked,
.checkbox[type=checkbox]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.checkbox[type=checkbox]:checked+label,
.checkbox[type=checkbox]:not(:checked)+label {
    font-family: OpenSansRegular;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: #262626;
}

.checkbox[type=checkbox]:checked+label:before,
.checkbox[type=checkbox]:not(:checked)+label:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #4c4c4c;
    background: rgba(255, 255, 255, 0);
}

.checkbox[type=checkbox]:checked+label:after,
.checkbox[type=checkbox]:not(:checked)+label:after {
    font-size: 12px;
    position: absolute;
    top: 6px;
    left: 0;
    width: 20px;
    height: 20px;
    content: ' ';
    transition: all .2s;
    border-radius: 3px;
    color: #fff;
    /* background: url(../../../public/img/product/logo_deus_II.png) center center no-repeat; */
}


.checkbox[type=checkbox]:not(:checked)+label:after {
    transform: scale(0);
    opacity: 0;
}