header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    opacity: 1;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-shadow: 0 3px 5px 0 transparent;
    -moz-box-shadow: 0 3px 5px 0 transparent;
    box-shadow: 0 3px 5px 0 transparent;
}

header .header-bas {
    padding-top: 5px;
    background: rgb(24, 24, 24);
}

header .header-bas .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .header-bas .logo {
    position: relative;
    top: -20px;
    transition: all 0.3s ease 0s;
    width: 90px;
}

.menu-toggle,
.menu-toggle * {
    transition: .25s ease-in-out
}

.menu-toggle {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
    z-index: -1;
    cursor: pointer;
    position: relative;
    top: -2px
}

.menu-toggle span {
    display: block;
    background: #fff;
    border-radius: 2px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.menu-toggle #hamburger {
    position: absolute;
    height: 100%;
    width: 100%
}

.menu-toggle #hamburger span {
    width: 30px;
    height: 3px;
    position: relative;
    top: 4px;
    left: 6px;
    margin: 6px 0
}

.menu-toggle #hamburger span:nth-child(1) {
    transition-delay: .5s
}

.menu-toggle #hamburger span:nth-child(2) {
    transition-delay: .625s
}

.menu-toggle #hamburger span:nth-child(3) {
    transition-delay: .75s
}

.menu-toggle #cross {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(45deg)
}

.menu-toggle #cross span:nth-child(1) {
    height: 0%;
    width: 4px;
    position: absolute;
    top: 10%;
    left: 18px;
    transition-delay: 0s
}

.menu-toggle #cross span:nth-child(2) {
    width: 0%;
    height: 4px;
    position: absolute;
    left: 10%;
    top: 18px;
    transition-delay: .25s
}

.menu-toggle.open span {
    background: #e31720
}

.menu-toggle.open #hamburger span {
    width: 0%
}

.menu-toggle.open #hamburger span:nth-child(1) {
    transition-delay: 0s
}

.menu-toggle.open #hamburger span:nth-child(2) {
    transition-delay: .125s
}

.menu-toggle.open #hamburger span:nth-child(3) {
    transition-delay: .25s
}

.menu-toggle.open #cross span:nth-child(1) {
    height: 80%;
    transition-delay: .625s
}

.menu-toggle.open #cross span:nth-child(2) {
    width: 80%;
    transition-delay: .375s
}

.menu-background {
    visibility: hidden;
    width: 300%;
    height: 300px;
    position: fixed;
    left: -130%;
    z-index: 8;
    background-color: #262626;
    -webkit-transition: background-position .5s, transform .5s .5s, visibility .5s 1s;
    transition: background-position .5s, transform .5s .5s, visibility .5s 1s;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden
}

.menu-background.top {
    -webkit-transform: rotate(-45deg) translateY(-150%);
    transform: rotate(-45deg) translateY(-150%);
    background: linear-gradient(to top, #e31720 50%, #262626 50%);
    background-size: 100% 200%;
    background-position: -100% 100%;
    will-change: transform;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden
}

.menu-background.middle {
    -webkit-transform: rotate(-45deg) translateY(50%) scaleY(0);
    transform: rotate(-45deg) translateY(50%) scaleY(0);
    background: #262626;
    will-change: transform;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden
}

.menu-background.bottom {
    -webkit-transform: rotate(-45deg) translateY(250%);
    transform: rotate(-45deg) translateY(250%);
    background: linear-gradient(to bottom, #262626 50%, #e31720 50%);
    background-size: 100% 200%;
    background-position: 0 -100%;
    will-change: transform;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden
}

.menu-background.active {
    visibility: visible;
    -webkit-transition: background-position .5s, transform .5s .5s;
    transition: background-position .5s, transform .5s .5s
}

.menu-background.active.top {
    -webkit-transform: rotate(-45deg) translateY(-49%);
    transform: rotate(-45deg) translateY(-49%);
    background-position: 0 0;
    will-change: transform
}

.menu-background.active.middle {
    -webkit-transform: rotate(-45deg) translateY(50%) scaleY(1);
    transform: rotate(-45deg) translateY(50%) scaleY(1);
    will-change: transform
}

.menu-background.active.bottom {
    -webkit-transform: rotate(-45deg) translateY(149%);
    transform: rotate(-45deg) translateY(149%);
    background-position: 0 0;
    will-change: transform
}

@media (min-width: 48em) {
    .menu-toggle {
        top: 0
    }

    .menu-background {
        height: 600px
    }
}

@media (min-width: 36em) {
    header .header-bas {
        background: #262626;
        padding-top: 0;
    }

    header .header-bas .logo {
        top: -12px;
    }
}

@media screen and (max-width: 576px) {
    .header-top {
        display: none;
    }

    .logo img {
        width: 50px;
        position: absolute;
    }
}

@media screen and (min-width: 768px) {
    .notouch header {
        background: #262626;
    }
}

@media (min-width: 75em) {
    header .header-bas .logo {
        width: 126px;
    }
}

@media screen and (max-width: 992px) {
    .menu-toggle {
        z-index: 999;
    }

    header nav {
        justify-content: flex-start;
        position: fixed;
        z-index: 99;
        top: 0;
        left: -100%;
    }
}