.dashboard-table th:first-child,
.dashboard-table td:first-child {
    text-align: center !important;
}

.dashboard-table tbody>tr>td:last-child {
    display: revert;
}

.dashboard-table tbody>tr {
    height: 80px;
    line-height: 80px;
}

.product-cover {
    width: 80px;

}