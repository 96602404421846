.wrapper-main-visuel {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.wrapper-main-visuel .caption {
    position: absolute;
    left: 15px;
    bottom: 0;
    height: 65%;
    width: 100%;
    z-index: 2;
}

.wrapper-main-visuel .caption span.line {
    position: absolute;
    right: auto;
    left: 15px;
    top: 10px;
    height: 0%;
    width: 2px;
    display: block;
    background: #fff;
}

.wrapper-main-visuel.left .caption span.line {
    left: 15px;
    right: auto;
}

.wrapper-main-visuel .caption h1 a,
.wrapper-main-visuel .caption h1 span {
    display: inline-block;
    width: 200px !important;
    margin: 0;
    /* font-family: SuiGenerisBk-Regular; */
    font-weight: 400 !important;
    font-style: normal;
    color: #fff !important;
    font-size: 24px !important;
    overflow: hidden;
    text-align: left;
}

.wrapper-main-visuel .caption h1 span.first {
    opacity: 1;
    display: block;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
}

.wrapper-main-visuel .caption h1.active span.first {
    -webkit-animation: showup 3s forwards;
    animation: showup 3s forwards;
}

.wrapper-main-visuel .caption h1 span.second {
    opacity: 1;
    display: block;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
}

.wrapper-main-visuel .caption h1.active span.second {
    -webkit-animation: showupSecond 3s .5s forwards;
    animation: showupSecond 3s .5s forwards;
}

.wrapper-main-visuel img {
    width: 100%;
}

.wrapper-main-visuel img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: left;
    -webkit-transform: scale(1) translateX(-50%) translateY(-50%) translateZ(0);
    transform: scale(1) translateX(-50%) translateY(-50%) translateZ(0);
    -webkit-backface-visibility: hidden;
}

.wrapper-main-visuel .update {
    position: absolute;
    right: 30px;
    bottom: 30px;
    display: block;
    z-index: 5;
    cursor: pointer;
}

.wrapper-main-visuel .update a.icon {
    cursor: pointer;
}

.wrapper-main-visuel .update a.icon span {
    display: inline-block;
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    text-align: right;
    line-height: 1.2;
    vertical-align: middle;
    opacity: 1;
    -webkit-transition: .3s;
    transition: .3s;
}

.wrapper-main-visuel .update a.icon:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: -5px;
    right: -8px;
    border: 0 solid #fff;
    border-radius: 100%;
    border-width: 8px;
    z-index: -1;
    animation: da-pulse 1.5s ease-in-out 0s infinite;
    -webkit-animation: da-pulse 1.5s ease-in-out 0s infinite;
}

.wrapper-main-visuel .update a.icon:after {
    content: '';
    width: 30px;
    height: 30px;
    display: inline-block;
    background: url(../../../public/img/staticPic/picto-info.svg) center center no-repeat;
    -webkit-background-size: contain;
    background-size: contain;
    vertical-align: middle;
    margin-left: 8px;
    border-radius: 100%;
}

.wrapper-main-visuel .update a.icon:hover {
    text-decoration: none
}

.wrapper-main-visuel .update:hover+.update-content {
    z-index: 4;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.wrapper-main-visuel .update-content {
    z-index: -1;
    position: absolute;
    right: 10px;
    bottom: 10px;
    max-width: calc(100% - 20px);
    border-radius: 10px;
    padding: 20px 60px 45px 20px;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, .1);
    background: #262626;
    font-family: OpenSansRegular;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    font-size: 14px;
    -webkit-transform: scale(.8);
    transform: scale(.8);
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.wrapper-main-visuel .update-content p {
    margin: 0;
    font-size: 14px;
}

.wrapper-main-visuel .update-content a {
    color: #e31720;
}

.wrapper-main-visuel .update-content p strong {
    font-family: OpenSansSemiBold;
    font-weight: 600;
    font-style: normal
}

.wrapper-description h2,
.wrapper-split-xl h3 {
    font-family: OpenSansRegular;
    font-style: normal;
    color: #fff
}

.wrapper-main-visuel .update-content:hover {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    z-index: 4
}

.wrapper-main-visuel .update-content.active {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.wrapper-main-visuel.left .caption {
    text-align: left
}

.wrapper-main-visuel.left .caption:before {
    left: -30px;
    right: auto
}

.wrapper-main-visuel.left .caption span.line {
    left: 15px;
    right: auto
}

.wrapper-main-visuel.left .caption h1.active span.first {
    -webkit-animation: showupB 3s forwards;
    animation: showupB 3s forwards
}

.wrapper-main-visuel.left .caption h1.active span.second {
    -webkit-animation: showupSecondB 3s .5s forwards;
    animation: showupSecondB 3s .5s forwards
}

.wrapper-main-visuel.left+#content .wrapper-description .loader-left {
    left: auto;
    right: 0;
    width: calc(((100% - 1170px)/ 2) + 1140px);
    background: #131313;
    border: 0;
    border-left: 2px solid #fff;
    z-index: 15
}

.wrapper-main-visuel.left+#content .wrapper-description .loader-right {
    top: 0;
    right: auto;
    left: 0;
    width: calc((100% - 1107px)/ 2);
    background: #131313;
    z-index: 14
}

.wrapper-main-img {
    height: 100vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
}

.mobile-img {
    display: none;
}

@media screen and (max-width: 768px) {
    .wrapper-main-visuel .update a.icon:before {
        display: none;
    }

    .wrapper-main-visuel .caption h1 a,
    .wrapper-main-visuel .caption h1 span {
        display: inline-block;
        width: 150px !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        color: #2f1e00 !important;
        line-height: 30px !important;
    }
}

@media (min-width: 480px) {
    .wrapper-main-visuel .caption h1 {
        font-size: calc(3.515625vw + 7.13px)
    }

    .wrapper-main-visuel .update-content {
        max-width: 50%;
    }
}


@media (max-width: 47.99em) {
    .wrapper-main-visuel .wrapper-main-img img {
        height: 100%;
        width: 100%;
    }
}

@media (min-width: 48em) {
    .wrapper-main-visuel .update-content {
        max-width: 50%
    }

    .wrapper-main-visuel .caption {
        height: 55%;
        right: auto;
        left: 100px;
        text-align: left
    }

    .wrapper-main-visuel .caption:before {
        content: '';
        position: absolute;
        top: 10px;
        right: auto;
        left: -30px;
        height: 0%;
        width: 2px;
        display: block;
        background: #fff;
        -webkit-animation: showupLine 3s 1.5s forwards;
        animation: showupLine 3s 1.5s forwards
    }

    @-webkit-keyframes showupLine {
        0% {
            opacity: 0;
            height: 0%
        }

        50% {
            opacity: 1
        }

        80% {
            margin-left: 0;
            height: 100%
        }

        100% {
            opacity: 1;
            height: 100%
        }
    }

    @-moz-keyframes showupLine {
        0% {
            opacity: 0;
            height: 0%
        }

        50% {
            opacity: 1
        }

        80% {
            margin-left: 0;
            height: 100%
        }

        100% {
            opacity: 1;
            height: 100%
        }
    }

    @-ms-keyframes showupLine {

        100%,
        50% {
            opacity: 1
        }

        100%,
        80% {
            height: 100%
        }

        0% {
            opacity: 0;
            height: 0%
        }

        80% {
            margin-left: 0
        }
    }

    @-o-keyframes showupLine {
        0% {
            opacity: 0;
            height: 0%
        }

        50% {
            opacity: 1
        }

        80% {
            margin-left: 0;
            height: 100%
        }

        100% {
            opacity: 1;
            height: 100%
        }
    }

    @keyframes showupLine {
        0% {
            opacity: 0;
            height: 0%
        }

        50% {
            opacity: 1
        }

        80% {
            margin-left: 0;
            height: 100%
        }

        100% {
            opacity: 1;
            height: 100%
        }
    }

    @-webkit-keyframes showupSecond {
        0% {
            opacity: 0;
            right: 800px
        }

        80% {
            right: 0
        }

        100% {
            opacity: 1;
            right: 0
        }
    }

    @-moz-keyframes showupSecond {
        0% {
            opacity: 0;
            right: 800px
        }

        80% {
            right: 0
        }

        100% {
            opacity: 1;
            right: 0
        }
    }

    @-ms-keyframes showupSecond {

        100%,
        80% {
            right: 0
        }

        0% {
            opacity: 0;
            right: 800px
        }

        100% {
            opacity: 1
        }
    }

    @-o-keyframes showupSecond {
        0% {
            opacity: 0;
            right: 800px
        }

        80% {
            right: 0
        }

        100% {
            opacity: 1;
            right: 0
        }
    }

    @keyframes showupSecond {
        0% {
            opacity: 0;
            right: 800px
        }

        80% {
            right: 0
        }

        100% {
            opacity: 1;
            right: 0
        }
    }

    @-webkit-keyframes showup {
        0% {
            margin-right: -800px;
            opacity: 0;
            margin-left: -800px
        }

        80% {
            margin-right: 0;
            margin-left: 0
        }

        100% {
            margin-right: 0;
            opacity: 1;
            margin-left: 0
        }
    }

    @-moz-keyframes showup {
        0% {
            margin-right: -800px;
            opacity: 0;
            margin-left: -800px
        }

        80% {
            margin-right: 0;
            margin-left: 0
        }

        100% {
            margin-right: 0;
            opacity: 1;
            margin-left: 0
        }
    }

    @-ms-keyframes showup {

        100%,
        80% {
            margin-right: 0;
            margin-left: 0
        }

        0% {
            margin-right: -800px;
            opacity: 0;
            margin-left: -800px
        }

        100% {
            opacity: 1
        }
    }

    @-o-keyframes showup {
        0% {
            margin-right: -800px;
            opacity: 0;
            margin-left: -800px
        }

        80% {
            margin-right: 0;
            margin-left: 0
        }

        100% {
            margin-right: 0;
            opacity: 1;
            margin-left: 0
        }
    }

    @keyframes showup {
        0% {
            margin-right: -800px;
            opacity: 0;
            margin-left: -800px
        }

        80% {
            margin-right: 0;
            margin-left: 0
        }

        100% {
            margin-right: 0;
            opacity: 1;
            margin-left: 0
        }
    }

    @-webkit-keyframes showupSecond {
        0% {
            opacity: 0;
            left: 800px
        }

        80% {
            left: 0
        }

        100% {
            opacity: 1;
            left: 0
        }
    }

    @-moz-keyframes showupSecond {
        0% {
            opacity: 0;
            left: 800px
        }

        80% {
            left: 0
        }

        100% {
            opacity: 1;
            left: 0
        }
    }

    @-ms-keyframes showupSecond {

        100%,
        80% {
            left: 0
        }

        0% {
            opacity: 0;
            left: 800px
        }

        100% {
            opacity: 1
        }
    }

    @-o-keyframes showupSecond {
        0% {
            opacity: 0;
            left: 800px
        }

        80% {
            left: 0
        }

        100% {
            opacity: 1;
            left: 0
        }
    }

    @keyframes showupSecond {
        0% {
            opacity: 0;
            left: 800px
        }

        80% {
            left: 0
        }

        100% {
            opacity: 1;
            left: 0
        }
    }

    .wrapper-main-visuel.left .caption span.line {
        left: 15px;
        right: auto
    }

    .wrapper-main-visuel a span {
        display: inline-block;
        font-family: OpenSansRegular;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        font-size: 10px;
        color: #fff;
        text-align: right;
        line-height: 1;
        vertical-align: middle
    }

    .wrapper-main-visuel .update a.icon:before {
        top: -2px;
        right: -5px;
    }
}

@media (min-width: 48em) and (max-width:61.99em) {
    .wrapper-main-visuel img {
        height: 92%;
        top: auto;
        bottom: 0;
        -webkit-transform: scale(1) translateZ(0);
        transform: scale(1) translateZ(0);
        left: -33%
    }

    .wrapper-main-visuel .wrapper-main-img img {
        left: 50%;
        -webkit-transform: scale(1) translateX(-50%) translateZ(0);
        transform: scale(1) translateX(-50%) translateZ(0)
    }

    .wrapper-main-visuel .update a.icon span {
        display: none;
    }

}

@media (min-width: 992px) {
    .wrapper-main-visuel .caption h1 {
        font-size: 42px
    }

    .wrapper-main-visuel img {
        height: 90%;
        top: auto;
        bottom: 0;
        -webkit-transform: scale(1) translateX(-50%) translateZ(0);
        transform: scale(1) translateX(-50%) translateZ(0)
    }
}

@media (min-width: 75em) {
    .wrapper-main-visuel .update-content {
        max-width: 25%;
    }
}

@-webkit-keyframes showup {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-moz-keyframes showup {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-ms-keyframes showup {

    100%,
    80% {
        margin-left: 0
    }

    0% {
        opacity: 0;
        margin-left: -800px
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes showup {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@keyframes showup {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-webkit-keyframes showupSecond {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-moz-keyframes showupSecond {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-ms-keyframes showupSecond {

    100%,
    80% {
        left: 0
    }

    0% {
        opacity: 0;
        left: 800px
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes showupSecond {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@keyframes showupSecond {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-webkit-keyframes showupB {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-moz-keyframes showupB {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-ms-keyframes showupB {

    100%,
    80% {
        margin-left: 0
    }

    0% {
        opacity: 0;
        margin-left: -800px
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes showupB {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@keyframes showupB {
    0% {
        opacity: 0;
        margin-left: -800px
    }

    80% {
        margin-left: 0
    }

    100% {
        opacity: 1;
        margin-left: 0
    }
}

@-webkit-keyframes showupSecondB {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-moz-keyframes showupSecondB {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-ms-keyframes showupSecondB {

    100%,
    80% {
        left: 0
    }

    0% {
        opacity: 0;
        left: 800px
    }

    100% {
        opacity: 1
    }
}

@-o-keyframes showupSecondB {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@keyframes showupSecondB {
    0% {
        opacity: 0;
        left: 800px
    }

    80% {
        left: 0
    }

    100% {
        opacity: 1;
        left: 0
    }
}

@-webkit-keyframes da-pulse {
    0% {
        transform: scale(.8);
        opacity: .8
    }

    100% {
        transform: scale(1.25);
        opacity: 0
    }
}

@-moz-keyframes da-pulse {
    0% {
        transform: scale(.8);
        opacity: .8
    }

    100% {
        transform: scale(1.25);
        opacity: 0
    }
}

@-ms-keyframes da-pulse {
    0% {
        transform: scale(.8);
        opacity: .8
    }

    100% {
        transform: scale(1.25);
        opacity: 0
    }
}

@-o-keyframes da-pulse {
    0% {
        transform: scale(.8);
        opacity: .8
    }

    100% {
        transform: scale(1.25);
        opacity: 0
    }
}

@keyframes da-pulse {
    0% {
        transform: scale(.8);
        opacity: .8
    }

    100% {
        transform: scale(1.25);
        opacity: 0
    }
}